@define-mixin ContentListVariant $backgroundColor, $textColor {
    background-color: $backgroundColor;
    color: $textColor;

    & .Body {
        & .MainItem {
            border-bottom-color: $textColor;
        }
    }
}

.ContentList {
    background-color: var(--blackLighten60LegacyColor);
    color: var(--blackBaseColor);
    padding: 64px 0;

    @media (--tablet) {
        padding: 80px 0 32px;
    }

    & .Inner {
        @mixin wrapper;
    }
}

.Title {
    @mixin h30;
    @mixin hyphenateWords;
    font-weight: 600;

    @media (--tablet) {
        @mixin h20;
    }
}

.Header {
    gap: var(--gutterWidth);
}

.Body {
    & .MainItem {
        padding-bottom: 32px;
        border-bottom: 1px solid var(--blackBaseColor);
        margin-bottom: 32px;

        @media (--tablet) {
            padding-bottom: 64px;
            margin-bottom: 64px;
        }
    }
}

.Items {
    @mixin clearList;

    @media (--tablet) {
        display: flex;
        flex-wrap: wrap;
    }

    & > li {
        margin-bottom: 32px;

        @media (--tablet) {
            margin-bottom: 64px;
        }
    }
}

.Inner {
    @mixin wrapper;
}

.Body--oneColumnVariant {
    @media (--tablet) {
        & .Items > li {
            width: 100%;
        }
    }
}

.Body--twoColumnVariant {
    @media (--tablet) {
        & .Items > li {
            lost-column: 6/12 2;
        }
    }
}

.Body--threeColumnVariant {
    @media (--tablet) {
        & .Items > li {
            lost-column: 4/12 3;
        }
    }
}

.Body--oneTopAndThreeBelowColumnVariant {
    @media (--tablet) {
        & .Items > li {
            lost-column: 4/12 3;
        }
    }
}

.Body--fourColumnVariant {
    @media (--tablet) {
        & .Items > li {
            lost-column: 3/12 4;
        }
    }
}

.Variant--titleTopLinkBottom {
    & .Header {
        display: flex;
        justify-content: center;

        & .Title {
            margin-bottom: 32px;

            @media (--tablet) {
                margin-bottom: 48px;
            }
        }
    }

    & .Body {
        margin-bottom: 48px;

        @media (--tablet) {
            margin-bottom: 16px;
        }
    }

    & .Footer {
        display: flex;
        justify-content: center;

        @media (--tablet) {
            margin-bottom: 80px;
        }
    }
}

.Variant--titleAndLinkTop {
    & .Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 64px;

        & .Title {
            margin-bottom: 0;
        }
    }
}

.Variant--red {
    @mixin ContentListVariant var(--primaryHKDarken40Color), var(--whiteBaseColor);
}

.Variant--white {
    @mixin ContentListVariant var(--whiteBaseColor), var(--blackLighten10Color);
}

.Variant--dark {
    @mixin ContentListVariant var(--blackLighten10Color), var(--whiteBaseColor);
}

.Variant--grey {
    @mixin ContentListVariant var(--blackLighten60Color), var(--blackLighten10Color);
}

.Variant--transparent {
    @mixin ContentListVariant transparent, var(--blackLighten10Color);
}
